<template>
  <div>
    <Card>
      <template #title>Variáveis de Margem</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="configuracoes"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="['chave', 'valor', 'descricao']"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} configurações"
          responsiveLayout="scroll">
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-success float-right"
              @click="showFormConfiguracao()" />
          </template>
          <Column field="chave" header="Chave"></Column>
          <Column field="valor" header="Valor"></Column>
          <Column field="descricao" header="Descrição"></Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                v-tooltip.left="'Editar'"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2 mt-2"
                @click="showFormConfiguracao(slotProps.data)" />
              <Button
                v-tooltip.left="'Excluir'"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger mr-2 mt-2"
                @click="confirmDeleteConfiguracao(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>
    <Dialog
      modal
      header="Header"
      :style="{ width: '350px' }"
      :visible.sync="deleteConfiguracaoDialog">
      <template #header>
        <h3>Excluir Configuração</h3>
      </template>

      Confirma a exclusão de {{ configuracao.chave }} ?

      <template #footer>
        <Button
          label="Não"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteConfiguracaoDialog = false" />
        <Button
          label="Sim"
          icon="pi pi-check"
          autofocus
          @click="deleteConfiguracao" />
      </template>
    </Dialog>
    <ConfiguracaoForm
      :visible.sync="showConfiguracaoFormDialog"
      :propsconfiguracao="configuracao"
      :update="update"
      @carregarDados="carregarDados"
      @onUpdate="onUpdateConfiguracao" />
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import ConfiguracaoForm from './ConfiguracaoForm.vue'
import ConfiguracaoService from '@/service/ConfiguracaoService.js'

export default {
  components: {
    ConfiguracaoForm,
  },
  data() {
    return {
      filtros: {},
      configuracoes: null,
      configuracao: {},
      deleteConfiguracaoDialog: false,
      loading: false,
      showConfiguracaoFormDialog: false,
      update: false,
    }
  },

  created() {
    this.configuracaoService = new ConfiguracaoService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    showFormConfiguracao(data) {
      if (data) {
        this.configuracao = data
        this.update = true
      } else {
        this.configuracao = {}
        this.update = false
      }
      this.showConfiguracaoFormDialog = true
    },

    onUpdateConfiguracao(event) {
      let index = this.configuracoes.findIndex((c) => c.id === event.id)
      index != -1
        ? this.$set(this.configuracoes, index, event)
        : this.configuracoes.unshift(event)
    },
    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    carregarDados() {
      this.loading = true
      this.configuracaoService.getListaConfiguracoes().then((res) => {
        this.configuracoes = res
        this.loading = false
      })
    },

    exibeToast() {
      this.$toast.add({
        severity: 'success',
        summary: 'Atualizado com sucesso!',
        life: 10000,
      })
    },

    confirmDeleteConfiguracao(data) {
      this.deleteConfiguracaoDialog = true
      this.configuracao = data
    },

    addConfiguracao() {
      this.$router.push({ name: 'configuracao-new' })
    },

    editConfiguracao(data) {
      this.$router.push({
        name: 'configuracao-edit',
        params: { id: data.chave },
      })
    },

    deleteConfiguracao() {
      this.deleteConfiguracaoDialog = false
      this.configuracaoService
        .deleteConfiguracao(this.configuracao.chave)
        .then(() => {
          this.carregarDados()
          this.configuracao = {}
          this.$toast.add({
            severity: 'success',
            summary: 'Excluido com sucesso!',
            life: 10000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style></style>
